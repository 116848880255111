import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { Spinner } from '../../components/spinner/Spinner';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import companyLogo from '../../assets/images/company-logo.svg';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import { config } from '../../Constants';

const Home = () => {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const baseURL = config.baseURL;

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(`Provide a valid email`)
        .required(`Email is required`),
      password: Yup.string().required(`Password is required`),
    }),
    onSubmit() {
      loginAdmin();
    },
  });

  const loginAdmin = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(
        `${baseURL}/auth/login/admin`,
        formik.values
      );
      secureLocalStorage.setItem(`user-info`, JSON.stringify(data));
      toast.success(`Login Successful`);
      setLoading(false);
      navigate(`/dashboard/overview`);
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.msg);
    }
  };

  return (
    <main className='auth-page'>
      <div className='logo'>
        <img src={companyLogo} alt='Raritrade' />
      </div>

      <form onSubmit={formik.handleSubmit}>
        <h2 className='title'>Admin Login</h2>

        {/* EMAIL */}
        <div className='form-control'>
          <label
            htmlFor='email'
            className={
              formik.errors.email && formik.touched.email ? `error` : ``
            }
          >
            {formik.touched.email && formik.errors.email
              ? formik.errors.email
              : `Email`}
          </label>
          <input
            type='email'
            name='email'
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </div>

        {/* PASSWORD */}
        <div className='form-control'>
          <label
            htmlFor='password'
            className={
              formik.errors.password && formik.touched.password ? `error` : ``
            }
          >
            {formik.touched.password && formik.errors.password
              ? formik.errors.password
              : `Password`}
          </label>
          <div className='password-cont'>
            <input
              type={showPassword ? `text` : `password`}
              name='password'
              id='password'
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {showPassword ? (
              <FaEye
                onClick={() => {
                  setShowPassword(false);
                }}
              />
            ) : (
              <FaEyeSlash
                onClick={() => {
                  setShowPassword(true);
                }}
              />
            )}
          </div>
        </div>

        <div className='btn'>
          <button className='primary' type='submit'>
            Continue {loading ? <Spinner /> : null}
          </button>
        </div>
      </form>
    </main>
  );
};

export default Home;
