import { Modal } from '@mantine/core';
import { AddUserForm } from '../forms/Forms';

export const AddUserModal = ({ opened, close }) => {
  return (
    <Modal
      opened={opened}
      onClose={close}
      centered
      size='lg'
      className='add-user-modal'
      overlayProps={{
        backgroundOpacity: 0.55,
      }}
    >
      <div className='title'>
        <h2>Add a user</h2>
      </div>

      <AddUserForm close={close} />
    </Modal>
  );
};
