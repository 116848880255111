const prod = {
  baseURL: `https://api.raritrade.com/api/v1`,
  apiURL: `https://api.raritrade.com`,
};

const dev = {
  baseURL: `https://api.raritrade.com/api/v1`,
  apiURL: `https://api.raritrade.com`,
};

export const config = process.env.NODE_ENV === `development` ? dev : prod;
