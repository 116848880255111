import { UpdateWalletForm } from '../../components/forms/Forms';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import { config } from '../../Constants';

const Wallets = () => {
  const { token } = JSON.parse(secureLocalStorage.getItem(`user-info`));
  const [bitcoin, setBitcoin] = useState({});
  const [ethereum, setEthereum] = useState({});
  const [usdt, setUsdt] = useState({});
  const [tron, setTron] = useState({});
  const [xrp, setXrp] = useState({});
  const baseURL = config.baseURL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(`${baseURL}/admin-wallets`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setBitcoin(data.filter((wallet) => wallet.walletName === `bitcoin`)[0]);
        setEthereum(
          data.filter((wallet) => wallet.walletName === `ethereum`)[0]
        );
        setUsdt(data.filter((wallet) => wallet.walletName === `usdt`)[0]);
        setTron(data.filter((wallet) => wallet.walletName === `tron`)[0]);
        setXrp(data.filter((wallet) => wallet.walletName === `xrp`)[0]);
      } catch (error) {}
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <main className='content wallets-page'>
      <h1 className='title'>Wallets</h1>

      <div className='block'>
        <div className='title-block'>
          <h2 className='title'>Bitcoin</h2>
          <h4 className='sub-title'>Update Wallet Address</h4>
        </div>
        <hr />
        <UpdateWalletForm data={bitcoin} />
      </div>

      <div className='block'>
        <div className='title-block'>
          <h2 className='title'>Ethereum</h2>
          <h4 className='sub-title'>Update Wallet Address</h4>
        </div>
        <hr />
        <UpdateWalletForm data={ethereum} />
      </div>

      <div className='block'>
        <div className='title-block'>
          <h2 className='title'>USDT</h2>
          <h4 className='sub-title'>Update Wallet Address</h4>
        </div>
        <hr />
        <UpdateWalletForm data={usdt} />
      </div>

      <div className='block'>
        <div className='title-block'>
          <h2 className='title'>Tron</h2>
          <h4 className='sub-title'>Update Wallet Address</h4>
        </div>
        <hr />
        <UpdateWalletForm data={tron} />
      </div>

      <div className='block'>
        <div className='title-block'>
          <h2 className='title'>XRP</h2>
          <h4 className='sub-title'>Update Wallet Address</h4>
        </div>
        <hr />
        <UpdateWalletForm data={xrp} />
      </div>
    </main>
  );
};

export default Wallets;
