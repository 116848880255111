'use client';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Select } from '@mantine/core';
import { Spinner } from '../../components/spinner/Spinner';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import ImageZoom from '../image-zoom/ImageZoom';
import secureLocalStorage from 'react-secure-storage';
import { useNavigate } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { config } from '../../Constants';

export const UpdatePasswordForm = () => {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const baseURL = config.baseURL;

  const { token } = JSON.parse(secureLocalStorage.getItem(`user-info`));

  const formik = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema: Yup.object({
      password: Yup.string().required(`Password is required`),
    }),
    onSubmit: async (values, { resetForm }) => {
      await updatePassword();
      resetForm();
    },
  });

  const updatePassword = async () => {
    try {
      setLoading(true);
      const { data } = await axios.patch(
        `${baseURL}/auth/admin/update-password`,
        formik.values,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      toast.success(data.msg);
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.msg);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      {/* NEW PASSWORD */}
      <div className='form-control'>
        <label
          htmlFor='password'
          className={
            formik.errors.password && formik.touched.password ? `error` : ``
          }
        >
          {formik.touched.password && formik.errors.password
            ? formik.errors.password
            : `New Password`}
        </label>
        <div className='password-cont'>
          <input
            type={showPassword ? `text` : `password`}
            id='password'
            name='password'
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {showPassword ? (
            <FaEye
              onClick={() => {
                setShowPassword(false);
              }}
            />
          ) : (
            <FaEyeSlash
              onClick={() => {
                setShowPassword(true);
              }}
            />
          )}
        </div>
      </div>

      <div className='btn'>
        <button className='primary' type='submit'>
          Update Password
          {loading ? <Spinner /> : null}
        </button>
      </div>
    </form>
  );
};

export const UpdateWhatsappNumberForm = ({ data }) => {
  const [loading, setLoading] = useState(false);
  const baseURL = config.baseURL;

  const { token } = JSON.parse(secureLocalStorage.getItem(`user-info`));
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      number: '',
    },
    validationSchema: Yup.object({
      number: Yup.string().required(`Whatsapp number is required`),
    }),
    onSubmit: async (values, { resetForm }) => {
      await updateNumber();
      resetForm();
    },
  });

  const updateNumber = async () => {
    try {
      setLoading(true);
      const { data: info } = await axios.patch(
        `${baseURL}/whatsapp/${data._id}`,
        formik.values,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      toast.success(info.msg);
      navigate(0);
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.msg);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      {/* NEW NUMBER */}
      <div className='form-control'>
        <label
          htmlFor='whatsapp'
          className={
            formik.errors.number && formik.touched.number ? `error` : ``
          }
        >
          {formik.touched.number && formik.errors.number
            ? formik.errors.number
            : `New WhatsApp Number`}
        </label>
        <input
          type='text'
          id='number'
          name='number'
          value={formik.values.number}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder={data.number}
        />
      </div>

      <div className='btn'>
        <button className='primary' type='submit'>
          Update Number
          {loading ? <Spinner /> : null}
        </button>
      </div>
    </form>
  );
};

export const UpdatePersonalInfoForm = ({
  email,
  fullname,
  userId,
  phone,
  country,
  maritalStatus,
  address,
  investmentGoal,
  accountTier,
}) => {
  const [loading, setLoading] = useState(false);
  const baseURL = config.baseURL;

  const { token } = JSON.parse(secureLocalStorage.getItem(`user-info`));
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      fullname: fullname,
      email: email,
      phone: phone,
      country: country,
      maritalStatus: maritalStatus,
      address: address,
      investmentGoal: investmentGoal,
      accountTier: accountTier,
    },
    onSubmit: async (value, { resetForm }) => {
      updateInfo();
      resetForm();
    },
  });

  const updateInfo = async () => {
    const {
      email,
      fullname,
      phone,
      country,
      maritalStatus,
      address,
      investmentGoal,
      accountTier,
    } = formik.values;

    const formData = new FormData();
    if (email) {
      formData.append(`email`, email);
    }
    if (fullname) {
      formData.append(`fullname`, fullname);
    }
    if (phone) {
      formData.append(`phone`, phone);
    }
    if (country) {
      formData.append(`country`, country);
    }
    if (maritalStatus) {
      formData.append(`maritalStatus`, maritalStatus);
    }
    if (address) {
      formData.append(`address`, address);
    }
    if (investmentGoal) {
      formData.append(`investmentGoal`, investmentGoal);
    }
    if (accountTier) {
      formData.append(`accountTier`, accountTier);
    }

    try {
      setLoading(true);
      const { data } = await axios.patch(
        `${baseURL}/users/${userId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setLoading(false);
      toast.success(data.msg);
      navigate(0);
    } catch (error) {
      setLoading(false);
      if (error.response) {
        toast.error(error.response.data.msg);
      } else {
        toast.error(error.message);
      }
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      {/* NAME & EMAIL */}
      <div className='form-row'>
        {/* fullname */}
        <div className='form-control'>
          <label
            htmlFor='fullname'
            className={
              formik.errors.fullname && formik.touched.fullname ? `error` : ``
            }
          >
            {formik.touched.fullname && formik.errors.fullname
              ? formik.errors.fullname
              : `Fullname`}
          </label>
          <input
            type='fullname'
            id='fullname'
            value={formik.values.fullname}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder={fullname}
          />
        </div>

        {/* email */}
        <div className='form-control'>
          <label
            htmlFor='email'
            className={
              formik.errors.email && formik.touched.email ? `error` : ``
            }
          >
            {formik.touched.email && formik.errors.email
              ? formik.errors.email
              : `Email`}
          </label>
          <input
            type='email'
            id='email'
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder={email}
          />
        </div>
      </div>

      {/* PHONE & COUNTRY */}
      <div className='form-row'>
        {/* phone */}
        <div className='form-control'>
          <label
            htmlFor='phone'
            className={
              formik.errors.phone && formik.touched.phone ? `error` : ``
            }
          >
            {formik.touched.phone && formik.errors.phone
              ? formik.errors.phone
              : `Phone Number`}
          </label>
          <input
            type='phone'
            id='phone'
            value={formik.values.phone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder={phone}
          />
        </div>

        {/* country */}
        <div className='form-control'>
          <label
            htmlFor='country'
            className={
              formik.errors.country && formik.touched.country ? `error` : ``
            }
          >
            {formik.touched.country && formik.errors.country
              ? formik.errors.country
              : `Country`}
          </label>
          <input
            type='text'
            id='country'
            value={formik.values.country}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder={country}
          />
        </div>
      </div>

      {/* ADDRESS & MARITAL STATUS */}
      <div className='form-row'>
        {/* address */}
        <div className='form-control'>
          <label
            htmlFor='address'
            className={
              formik.errors.address && formik.touched.address ? `error` : ``
            }
          >
            {formik.touched.address && formik.errors.address
              ? formik.errors.address
              : `Address`}
          </label>
          <input
            type='text'
            id='address'
            value={formik.values.address}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder={address}
          />
        </div>

        {/* marital status */}
        <div className='form-control'>
          <label
            htmlFor='maritalStatus'
            className={
              formik.errors.maritalStatus && formik.touched.maritalStatus
                ? `error`
                : ``
            }
          >
            {formik.touched.maritalStatus && formik.errors.maritalStatus
              ? formik.errors.maritalStatus
              : `Marital Status`}
          </label>
          <input
            type='maritalStatus'
            id='maritalStatus'
            value={formik.values.maritalStatus}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder={maritalStatus}
          />
        </div>
      </div>

      {/* INVESTMENT GOAL & ACCOUNT TIER */}
      <div className='form-row'>
        {/* investment goal */}
        <div className='form-control'>
          <label
            htmlFor='investmentGoal'
            className={
              formik.errors.investmentGoal && formik.touched.investmentGoal
                ? `error`
                : ``
            }
          >
            {formik.touched.investmentGoal && formik.errors.investmentGoal
              ? formik.errors.investmentGoal
              : `Investment Goal`}
          </label>
          <input
            type='text'
            id='investmentGoal'
            value={formik.values.investmentGoal}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder={investmentGoal}
          />
        </div>

        {/* account tier */}
        <div className='form-control'>
          <label
            htmlFor='accountTier'
            className={
              formik.errors.accountTier && formik.touched.accountTier
                ? `error`
                : ``
            }
          >
            {formik.touched.accountTier && formik.errors.accountTier
              ? formik.errors.accountTier
              : `Account Tier`}
          </label>
          <input
            type='text'
            id='accountTier'
            value={formik.values.accountTier}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder={accountTier}
          />
        </div>
      </div>

      <div className='btn'>
        <button className='primary' type='submit'>
          Update Info
          {loading ? <Spinner /> : null}
        </button>
      </div>
    </form>
  );
};

export const UpdateFundsInfoForm = ({
  profits,
  deposits,
  bonus,
  pendingDeposits,
  userId,
  moneyEarned,
}) => {
  const [loading, setLoading] = useState(false);
  const baseURL = config.baseURL;

  const { token } = JSON.parse(secureLocalStorage.getItem(`user-info`));
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      profits: ``,
      bonus: ``,
      deposits: ``,
      pendingDeposits: ``,
      moneyEarned: ``,
    },
    validationSchema: Yup.object({
      profits: Yup.string().matches(/^[0-9]+$/, 'Numbers only'),
      bonus: Yup.string().matches(/^[0-9]+$/, 'Numbers only'),
      deposits: Yup.string().matches(/^[0-9]+$/, 'Numbers only'),
      pendingDeposits: Yup.string().matches(/^[0-9]+$/, 'Numbers only'),
      moneyEarned: Yup.string().matches(/^[0-9]+$/, 'Numbers only'),
    }),
    onSubmit: async (values, { resetForm }) => {
      await UpdateInfo();
      resetForm();
    },
  });

  const UpdateInfo = async () => {
    const { bonus, profits, deposits, pendingDeposits, moneyEarned } =
      formik.values;

    const formData = new FormData();
    if (bonus) {
      formData.append(`bonus`, bonus);
    }
    if (profits) {
      formData.append(`profits`, profits);
    }
    if (deposits) {
      formData.append(`deposits`, deposits);
    }
    if (pendingDeposits) {
      formData.append(`pendingDeposits`, pendingDeposits);
    }
    if (moneyEarned) {
      formData.append(`moneyEarned`, moneyEarned);
    }

    try {
      setLoading(true);
      const { data } = await axios.patch(
        `${baseURL}/users/${userId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setLoading(false);
      toast.success(data.msg);
      navigate(0);
    } catch (error) {
      setLoading(false);
      if (error.response) {
        toast.error(error.response.data.msg);
      } else {
        toast.error(error.message);
      }
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      {/* PROFITS & DEPOSITS */}
      <div className='form-row'>
        {/* profits */}
        <div className='form-control'>
          <label
            htmlFor='profits'
            className={
              formik.errors.profits && formik.touched.profits ? `error` : ``
            }
          >
            {formik.touched.profits && formik.errors.profits
              ? formik.errors.profits
              : `Total Profits`}
          </label>
          <input
            type='profits'
            id='profits'
            value={formik.values.profits}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder={`$${profits}`}
          />
        </div>

        {/* deposits */}
        <div className='form-control'>
          <label
            htmlFor='deposits'
            className={
              formik.errors.deposits && formik.touched.deposits ? `error` : ``
            }
          >
            {formik.touched.deposits && formik.errors.deposits
              ? formik.errors.deposits
              : `Total Deposits`}
          </label>
          <input
            type='text'
            id='deposits'
            value={formik.values.deposits}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder={`$${deposits}`}
          />
        </div>
      </div>

      {/* PENDING DEPOSITS & BONUS */}
      <div className='form-row'>
        {/* pending deposits */}
        <div className='form-control'>
          <label
            htmlFor='pendingDeposits'
            className={
              formik.errors.pendingDeposits && formik.touched.pendingDeposits
                ? `error`
                : ``
            }
          >
            {formik.touched.pendingDeposits && formik.errors.pendingDeposits
              ? formik.errors.pendingDeposits
              : `Pending Deposits`}
          </label>
          <input
            type='pendingDeposits'
            id='pendingDeposits'
            value={formik.values.pendingDeposits}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder={`$${pendingDeposits}`}
          />
        </div>

        {/* bonus */}
        <div className='form-control'>
          <label
            htmlFor='bonus'
            className={
              formik.errors.bonus && formik.touched.bonus ? `error` : ``
            }
          >
            {formik.touched.bonus && formik.errors.bonus
              ? formik.errors.bonus
              : `Total Bonus`}
          </label>
          <input
            type='text'
            id='bonus'
            value={formik.values.bonus}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder={`$${bonus}`}
          />
        </div>
      </div>

      {/* MONEY EARNED &  */}
      <div className='form-row'>
        {/* money earned */}
        <div className='form-control'>
          <label
            htmlFor='moneyEarned'
            className={
              formik.errors.moneyEarned && formik.touched.moneyEarned
                ? `error`
                : ``
            }
          >
            {formik.touched.moneyEarned && formik.errors.moneyEarned
              ? formik.errors.moneyEarned
              : `Money Earned this week`}
          </label>
          <input
            type='moneyEarned'
            id='moneyEarned'
            value={formik.values.moneyEarned}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder={`$${moneyEarned}`}
          />
        </div>
      </div>

      <div className='btn'>
        <button className='primary' type='submit'>
          Update Info
          {loading ? <Spinner /> : null}
        </button>
      </div>
    </form>
  );
};

export const UpdateNotificationForm = ({ notification, userId }) => {
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState(notification.title);
  const [content, setContent] = useState(``);
  const [persistent, setPersistent] = useState(notification.persistent);
  const baseURL = config.baseURL;

  const { token } = JSON.parse(secureLocalStorage.getItem(`user-info`));
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    UpdateNotification();
  };

  const UpdateNotification = async () => {
    const formData = new FormData();
    formData.append(`title`, title);
    formData.append(`content`, content);
    formData.append(`persistent`, persistent === `true` ? true : false);
    formData.append(`seen`, false);

    try {
      setLoading(true);
      const { data } = await axios.patch(
        `${baseURL}/users/${userId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setLoading(false);
      toast.success(data.msg);
      navigate(0);
    } catch (error) {
      setLoading(false);
      if (error.response) {
        toast.error(error.response.data.msg);
      } else {
        toast.error(error.message);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {/* title */}
      <div className='form-control'>
        <label htmlFor='title'>Notification title</label>
        <input
          type='text'
          name='title'
          id='title'
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>

      {/* conntent */}
      <div className='form-control'>
        <label htmlFor='content'>Notification Content</label>
        <CKEditor
          editor={ClassicEditor}
          data={notification?.content}
          onChange={(event, editor) => {
            if (!editor.getData()) {
              setContent(``);
            }
            setContent(editor.getData());
          }}
        />
      </div>

      <div className='form-control'>
        <div className='select-status'>
          <Select
            label='Will this notification be persistent?'
            required
            name='persistent'
            placeholder={
              persistent
                ? `This notification will be persistent`
                : `This notification will not be persistent`
            }
            onChange={(value) => setPersistent(value)}
            data={[
              { value: 'false', label: 'No' },
              { value: 'true', label: 'Yes' },
            ]}
          />
        </div>
      </div>

      <div className='form-control'>
        <label htmlFor='title'>Seen Status</label>
        <input
          type='text'
          name='title'
          id='title'
          value={
            notification.seen
              ? `This notification has been seen`
              : `This notification has not been seen`
          }
          contentEditable={false}
        />
      </div>

      <div className='btn'>
        <button className='primary' type='submit'>
          Update Notification
          {loading ? <Spinner /> : null}
        </button>
      </div>
    </form>
  );
};

export const UpdateDepositStatusForm = ({ depositId, status }) => {
  const [loading, setLoading] = useState(false);
  const baseURL = config.baseURL;

  const { token } = JSON.parse(secureLocalStorage.getItem(`user-info`));
  const navigate = useNavigate();
  const [newStatus, setNewStatus] = useState(``);

  const updateDepositStatus = async () => {
    const formData = new FormData();
    if (newStatus) {
      formData.append(`status`, newStatus);
    }

    try {
      setLoading(true);
      const { data } = await axios.patch(
        `${baseURL}/deposits/admin/${depositId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setLoading(false);
      toast.success(data.msg);
      navigate(0);
    } catch (error) {
      setLoading(false);
      if (error.response) {
        toast.error(error.response.data.msg);
      } else {
        toast.error(error.message);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateDepositStatus();
  };

  return (
    <form onSubmit={handleSubmit}>
      {/* crypto type */}
      <div className='select-status'>
        <Select
          label='Choose the new deposit status'
          required
          name='type'
          placeholder={status}
          onChange={(value) => setNewStatus(value)}
          data={[
            { value: 'pending', label: 'Pending' },
            { value: 'approved', label: 'Approved' },
            { value: 'failed', label: 'Failed' },
          ]}
        />
      </div>

      <div className='btn'>
        <button className='primary' type='submit'>
          Update Status
          {loading ? <Spinner /> : null}
        </button>
      </div>
    </form>
  );
};

export const UpdateWithdrawalStatusForm = ({ withdrawalId, status }) => {
  const [loading, setLoading] = useState(false);
  const baseURL = config.baseURL;

  const { token } = JSON.parse(secureLocalStorage.getItem(`user-info`));
  const navigate = useNavigate();
  const [newStatus, setNewStatus] = useState(``);

  const updateWithdrawalStatus = async () => {
    const formData = new FormData();
    if (newStatus) {
      formData.append(`status`, newStatus);
    }

    try {
      setLoading(true);
      const { data } = await axios.patch(
        `${baseURL}/withdrawals/admin/${withdrawalId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setLoading(false);
      toast.success(data.msg);
      navigate(0);
    } catch (error) {
      setLoading(false);
      if (error.response) {
        toast.error(error.response.data.msg);
      } else {
        toast.error(error.message);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateWithdrawalStatus();
  };

  return (
    <form onSubmit={handleSubmit}>
      {/* crypto type */}
      <div className='select-status'>
        <Select
          label='Choose the new withdrawal status'
          required
          name='type'
          placeholder={status}
          onChange={(value) => setNewStatus(value)}
          data={[
            { value: 'pending', label: 'Pending' },
            { value: 'approved', label: 'Approved' },
            { value: 'failed', label: 'Failed' },
          ]}
        />
      </div>

      <div className='btn'>
        <button className='primary' type='submit'>
          Update Status
          {loading ? <Spinner /> : null}
        </button>
      </div>
    </form>
  );
};

export const UpdateWalletForm = ({ data }) => {
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState({});
  const navigate = useNavigate();
  const { token } = JSON.parse(secureLocalStorage.getItem(`user-info`));
  const baseURL = config.baseURL;
  const apiURL = config.apiURL;

  const formik = useFormik({
    initialValues: {
      address: '',
    },
    onSubmit: async (values, { resetForm }) => {
      await updateWallets();
      resetForm();
    },
  });

  const updateWallets = async () => {
    const { address } = formik.values;
    const formData = new FormData();

    if (address) {
      formData.append(`address`, address);
    }
    if (image[0]) {
      formData.append(`image`, image[0]);
    }

    try {
      setLoading(true);
      const { data: info } = await axios.patch(
        `${baseURL}/admin-wallets/${data._id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      console.log(info);
      toast.success(`Wallet Updated`);
      navigate(0);
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.msg);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      {/* address */}
      <div className='form-control'>
        <label
          htmlFor={data.walletName}
          className={
            formik.errors.address && formik.touched.address ? `error` : ``
          }
        >
          {formik.touched.address && formik.errors.address
            ? formik.errors.address
            : `${data.walletName} Address`}
        </label>
        <input
          type='text'
          id='address'
          value={formik.values.address}
          name='address'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder={data.address}
        />
      </div>

      {/* qr code */}
      <div className='form-control'>
        <label htmlFor='qr-code'>New QR Code</label>
        <input
          type='file'
          id='qr-code'
          onChange={(e) => setImage(e.target.files)}
        />
      </div>

      {/* old qr code */}
      <div className='old-qr-code'>
        <ImageZoom
          alt='qr code'
          height={500}
          width={500}
          imageSrc={`${apiURL}/${data.image}`}
        />
      </div>

      <div className='btn'>
        <button className='primary' type='submit'>
          Update Address
          {loading ? <Spinner /> : null}
        </button>
      </div>
    </form>
  );
};

export const UpdateKYCStatusForm = ({ kycId, status }) => {
  const [loading, setLoading] = useState(false);
  const baseURL = config.baseURL;

  const { token } = JSON.parse(secureLocalStorage.getItem(`user-info`));
  const navigate = useNavigate();
  const [newStatus, setNewStatus] = useState(``);

  const updateDepositStatus = async () => {
    const formData = new FormData();
    if (newStatus) {
      formData.append(`status`, newStatus);
    }

    try {
      setLoading(true);
      const { data } = await axios.patch(
        `${baseURL}/kyc/admin/${kycId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setLoading(false);
      toast.success(data.msg);
      navigate(0);
    } catch (error) {
      setLoading(false);
      if (error.response) {
        toast.error(error.response.data.msg);
      } else {
        toast.error(error.message);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateDepositStatus();
  };

  return (
    <form onSubmit={handleSubmit}>
      {/* crypto type */}
      <div className='select-status'>
        <Select
          label='Choose the new deposit status'
          required
          name='type'
          placeholder={status}
          onChange={(value) => setNewStatus(value)}
          data={[
            { value: 'pending', label: 'Pending' },
            { value: 'approved', label: 'Approved' },
            { value: 'failed', label: 'Failed' },
          ]}
        />
      </div>

      <div className='btn'>
        <button className='primary' type='submit'>
          Update Status
          {loading ? <Spinner /> : null}
        </button>
      </div>
    </form>
  );
};

export const SendEmailForm = ({ email }) => {
  const [loading, setLoading] = useState(false);
  const baseURL = config.baseURL;

  const { token } = JSON.parse(secureLocalStorage.getItem(`user-info`));
  const navigate = useNavigate();
  const [message, setMessage] = useState(``);

  const formik = useFormik({
    initialValues: {
      subject: ``,
    },
    validationSchema: Yup.object({
      subject: Yup.string().required(`Email subject is required`),
    }),
    onSubmit: async (values, { resetForm }) => {
      await sendEmail();
      resetForm();
    },
  });

  const sendEmail = async () => {
    const { subject } = formik.values;

    const formData = new FormData();
    if (message) {
      formData.append(`message`, message);
    }
    if (subject) {
      formData.append(`subject`, subject);
    }

    try {
      setLoading(true);
      const { data } = await axios.post(
        `${baseURL}/send-emails/${email}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setLoading(false);
      toast.success(data.msg);
      navigate(0);
    } catch (error) {
      setLoading(false);
      if (error.response) {
        toast.error(error.response.data.msg);
      } else {
        toast.error(error.message);
      }
      toast.error(error.response.data.msg);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      {/* subject */}
      <div className='form-control'>
        <label
          htmlFor='subject'
          className={
            formik.errors.subject && formik.touched.subject ? `error` : ``
          }
        >
          {formik.touched.subject && formik.errors.subject
            ? formik.errors.subject
            : `Email Subject`}
        </label>
        <input
          type='text'
          name='subject'
          id='subject'
          value={formik.values.subject}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder='Enter Email Subject here'
        />
      </div>

      {/* message */}
      <div className='form-control'>
        <label
          htmlFor='message'
          className={
            formik.errors.message && formik.touched.message ? `error` : ``
          }
        >
          {formik.touched.message && formik.errors.message
            ? formik.errors.message
            : `Message`}
        </label>
        <CKEditor
          editor={ClassicEditor}
          data=''
          onChange={(event, editor) => {
            setMessage(editor.getData());
          }}
        />
      </div>

      <div className='btn'>
        <button className='primary' type='submit'>
          Send Email
          {loading ? <Spinner /> : null}
        </button>
      </div>
    </form>
  );
};

export const SendBulkEmailsForm = () => {
  const [loading, setLoading] = useState(false);
  const baseURL = config.baseURL;

  const { token } = JSON.parse(secureLocalStorage.getItem(`user-info`));
  const navigate = useNavigate();
  const [message, setMessage] = useState(``);

  const formik = useFormik({
    initialValues: {
      subject: ``,
    },
    validationSchema: Yup.object({
      subject: Yup.string().required(`Email subject is required`),
    }),
    onSubmit: async (values, { resetForm }) => {
      await sendEmail();
      resetForm();
    },
  });

  const sendEmail = async () => {
    const { subject } = formik.values;

    const formData = new FormData();
    if (message) {
      formData.append(`message`, message);
    }
    if (subject) {
      formData.append(`subject`, subject);
    }

    try {
      setLoading(true);
      const { data } = await axios.post(`${baseURL}/send-emails/`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      setLoading(false);
      toast.success(data.msg);
      navigate(0);
    } catch (error) {
      setLoading(false);
      if (error.response) {
        toast.error(error.response.data.msg);
      } else {
        toast.error(error.message);
      }
      toast.error(error.response.data.msg);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      {/* subject */}
      <div className='form-control'>
        <label
          htmlFor='subject'
          className={
            formik.errors.subject && formik.touched.subject ? `error` : ``
          }
        >
          {formik.touched.subject && formik.errors.subject
            ? formik.errors.subject
            : `Email Subject`}
        </label>
        <input
          type='text'
          name='subject'
          id='subject'
          value={formik.values.subject}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder='Enter Email Subject here'
        />
      </div>

      {/* message */}
      <div className='form-control'>
        <label
          htmlFor='message'
          className={
            formik.errors.message && formik.touched.message ? `error` : ``
          }
        >
          {formik.touched.message && formik.errors.message
            ? formik.errors.message
            : `Message`}
        </label>
        <CKEditor
          editor={ClassicEditor}
          data=''
          onChange={(event, editor) => {
            setMessage(editor.getData());
          }}
        />
      </div>

      <div className='btn'>
        <button className='primary' type='submit'>
          Send Email
          {loading ? <Spinner /> : null}
        </button>
      </div>
    </form>
  );
};

export const DeleteUserForm = ({ id }) => {
  const [loading, setLoading] = useState(false);
  const baseURL = config.baseURL;

  const { token } = JSON.parse(secureLocalStorage.getItem(`user-info`));
  const [opened, { open, close }] = useDisclosure(false);

  const navigate = useNavigate();

  const deleteUser = async () => {
    try {
      setLoading(true);
      const { data } = await axios.delete(`${baseURL}/users/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      setLoading(false);
      toast.success(data.msg);
      close();
      navigate(`/dashboard/users`);
    } catch (error) {
      setLoading(false);
      if (error.response) {
        toast.error(error.response.data.msg);
      } else {
        toast.error(error.message);
      }
      toast.error(error.response.data.msg);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    open();
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className='btn'>
        <button className='red' type='submit'>
          Delete User
        </button>
      </div>

      <Modal
        opened={opened}
        onClose={close}
        centered
        size='lg'
        className='delete-user-modal'
        overlayProps={{
          backgroundOpacity: 0.55,
        }}
      >
        <div className='warning'>
          <h2>Are you sure you want to delete this user?</h2>
        </div>

        <div className='btns'>
          <button className='primary' onClick={deleteUser}>
            Yes, I am sure {loading ? <Spinner /> : null}
          </button>
          <button className='grey' onClick={close}>
            Cancel
          </button>
        </div>
      </Modal>
    </form>
  );
};

export const UpdateUserWithdrawalStatusForm = ({ userId, userCanWithdraw }) => {
  const [loading, setLoading] = useState(false);
  const baseURL = config.baseURL;

  const { token } = JSON.parse(secureLocalStorage.getItem(`user-info`));
  const navigate = useNavigate();
  const [newStatus, setNewStatus] = useState(``);

  const updateDepositStatus = async () => {
    const value = newStatus === `true` ? true : false;

    const formData = new FormData();
    if (newStatus) {
      formData.append(`userCanWithdraw`, value);
    }

    try {
      setLoading(true);
      const { data } = await axios.patch(
        `${baseURL}/users/${userId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setLoading(false);
      toast.success(data.msg);
      navigate(0);
    } catch (error) {
      setLoading(false);
      if (error.response) {
        toast.error(error.response.data.msg);
      } else {
        toast.error(error.message);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateDepositStatus();
  };

  return (
    <form onSubmit={handleSubmit}>
      {/* crypto type */}
      <div className='form-control'>
        <Select
          label='Choose the new deposit status'
          required
          name='type'
          className='withdrawal-select'
          placeholder={
            userCanWithdraw
              ? `User can withdraw now`
              : `User can't withdraw right now`
          }
          onChange={(value) => setNewStatus(value)}
          data={[
            { value: 'false', label: 'Do not allow user to withdraw' },
            { value: 'true', label: 'Allow user to withdraw' },
          ]}
        />
      </div>

      <div className='btn'>
        <button className='primary' type='submit'>
          Update Withdrawal Status
          {loading ? <Spinner /> : null}
        </button>
      </div>
    </form>
  );
};

export const UpdateUserVerificationStatusForm = ({ userId, isVerified }) => {
  const [loading, setLoading] = useState(false);
  const baseURL = config.baseURL;

  const { token } = JSON.parse(secureLocalStorage.getItem(`user-info`));
  const navigate = useNavigate();
  const [newStatus, setNewStatus] = useState(``);

  const updateDepositStatus = async () => {
    const value = newStatus === `true` ? true : false;

    const formData = new FormData();
    if (newStatus) {
      formData.append(`isVerified`, value);
    }

    try {
      setLoading(true);
      const { data } = await axios.patch(
        `${baseURL}/users/${userId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setLoading(false);
      toast.success(data.msg);
      navigate(0);
    } catch (error) {
      setLoading(false);
      if (error.response) {
        toast.error(error.response.data.msg);
      } else {
        toast.error(error.message);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateDepositStatus();
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className='form-control'>
        <Select
          label='Choose the new deposit status'
          required
          name='type'
          className='withdrawal-select'
          value={isVerified ? `User is verified` : `User is unverified`}
          placeholder={
            isVerified ? `User is verified already` : `User is unverified`
          }
          onChange={(value) => {
            if (!value) {
              setNewStatus();
            }
            setNewStatus(value);
          }}
          data={[
            { value: 'false', label: 'Unverify this user' },
            { value: 'true', label: 'Verify this user' },
          ]}
        />
      </div>

      <div className='btn'>
        <button className='primary' type='submit'>
          Update Verification Status
          {loading ? <Spinner /> : null}
        </button>
      </div>
    </form>
  );
};

export const AddUserForm = ({ close }) => {
  const baseURL = config.baseURL;
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { token } = JSON.parse(secureLocalStorage.getItem(`user-info`));
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      fullname: ``,
      email: ``,
      phone: ``,
      country: ``,
      password: ``,
    },
    onSubmit: async (value, { resetForm }) => {
      await updateInfo();
      close();
    },
  });

  const updateInfo = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(
        `${baseURL}/auth/register/admin-reg`,
        formik.values,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setLoading(false);
      toast.success(data.msg);
      navigate(0);
    } catch (error) {
      setLoading(false);
      if (error.response) {
        toast.error(error.response.data.msg);
      } else {
        toast.error(error.message);
      }
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      {/* NAME & EMAIL */}
      <div className='form-row'>
        {/* fullname */}
        <div className='form-control'>
          <label
            htmlFor='fullname'
            className={
              formik.errors.fullname && formik.touched.fullname ? `error` : ``
            }
          >
            {formik.touched.fullname && formik.errors.fullname
              ? formik.errors.fullname
              : `Fullname`}
          </label>
          <input
            type='fullname'
            id='fullname'
            value={formik.values.fullname}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </div>

        {/* email */}
        <div className='form-control'>
          <label
            htmlFor='email'
            className={
              formik.errors.email && formik.touched.email ? `error` : ``
            }
          >
            {formik.touched.email && formik.errors.email
              ? formik.errors.email
              : `Email`}
          </label>
          <input
            type='email'
            id='email'
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </div>
      </div>

      {/* PHONE & COUNTRY */}
      <div className='form-row'>
        {/* phone */}
        <div className='form-control'>
          <label
            htmlFor='phone'
            className={
              formik.errors.phone && formik.touched.phone ? `error` : ``
            }
          >
            {formik.touched.phone && formik.errors.phone
              ? formik.errors.phone
              : `Phone Number`}
          </label>
          <input
            type='phone'
            id='phone'
            value={formik.values.phone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </div>

        {/* country */}
        <div className='form-control'>
          <label
            htmlFor='country'
            className={
              formik.errors.country && formik.touched.country ? `error` : ``
            }
          >
            {formik.touched.country && formik.errors.country
              ? formik.errors.country
              : `Country`}
          </label>
          <input
            type='text'
            id='country'
            value={formik.values.country}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </div>
      </div>

      {/* PASSWORD & */}
      <div className='form-row'>
        {/* password */}
        <div className='form-control'>
          <label
            htmlFor='password'
            className={
              formik.errors.password && formik.touched.password ? `error` : ``
            }
          >
            {formik.touched.password && formik.errors.password
              ? formik.errors.password
              : `Password`}
          </label>
          <div className='password-cont'>
            <input
              type={showPassword ? `text` : `password`}
              name='password'
              id='password'
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {showPassword ? (
              <FaEye
                onClick={() => {
                  setShowPassword(false);
                }}
              />
            ) : (
              <FaEyeSlash
                onClick={() => {
                  setShowPassword(true);
                }}
              />
            )}
          </div>
        </div>
      </div>

      <div className='btn'>
        <button className='primary' type='submit'>
          Create User
          {loading ? <Spinner /> : null}
        </button>
      </div>
    </form>
  );
};
